<template>
    <div class="mx-auto container container--fluid">
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat color="white" dense>
            <v-toolbar-title>Role Links</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
        <v-divider class="mx-4"></v-divider>
        <div id="inspire">

            <template>

                <v-data-table :headers="headers" :items="rolelink_list" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line
                                hide-details></v-text-field>
                            <v-spacer></v-spacer><span style="width: 20px"></span>

                            <v-spacer></v-spacer><span style="width: 20px"></span>
                            <v-dialog v-model="dialog" max-width="600px">

                                <v-card>
                                    <v-form>
                                        <v-card-title>
                                            <v-row justify="space-between">
                                                <span class="headline">New Role Links</span>
                                                <v-btn icon dark @click="dialog = false">
                                                    <v-icon color="black">mdi-close</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="linkname" persistent-hint
                                                            :rules="[v => !!v || 'required']" label="Link Name *"
                                                            dense></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="sort_order" persistent-hint
                                                            :hint="'For example ::  1, 2, 3'" label="Sort Order *"
                                                            :rules="[v => !!v || 'required']"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="icon" persistent-hint
                                                            :rules="[v => !!v || 'required']" label="Icon *"
                                                            dense></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="linkdescription" label="Link Description *"
                                                            dense></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="link" persistent-hint
                                                            :rules="[v => !!v || 'required']" label="Link URL*"
                                                            dense></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-autocomplete :items="user_type_list" v-model="usertype"
                                                            item-text="name" item-value="id" label="Select User Type *"
                                                            :rules="[v => !!v || 'required']"></v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">
                                                        <v-autocomplete multiple :items="role_list" v-model="role"
                                                            label="Select Role *" item-text="name" item-value="id"
                                                            :rules="[v => !!v || 'required']"></v-autocomplete>
                                                    </v-col>


                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                            <v-btn color="primary darken-1" @click="save">Save</v-btn>
                                            <!-- :disabled="!valid" -->
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialog2" max-width="500px">

                                <v-card>
                                    <v-form>
                                        <v-card-title>
                                            <v-row justify="space-between">
                                                <span class="headline"> Edit Company</span>
                                                <v-btn icon dark @click="dialog2 = false">
                                                    <v-icon color="black">mdi-close</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>

                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="linkname" persistent-hint
                                                            :rules="[v => !!v || 'required']" label="Link Name *"
                                                            dense></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="sort_order" persistent-hint
                                                            :hint="'For example ::  1, 2, 3'" label="Sort Order *"
                                                            :rules="[v => !!v || 'required']"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="icon" persistent-hint
                                                            :rules="[v => !!v || 'required']" label="Icon *"
                                                            dense></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="linkdescription" label="Link Description *"
                                                            dense></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="link" persistent-hint
                                                            :rules="[v => !!v || 'required']" label="Link URL*"
                                                            dense></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">User Type
                                                        <v-select dense v-model="usertype" :items="user_type_list"
                                                            persistent-hint item-text="name" item-value="id"
                                                            :rules="[v => !!v || 'required']"></v-select>
                                                    </v-col>

                                                    <v-col cols="12" sm="6">Role
                                                        <v-select dense v-model="role" :items="role_list" item-text="name"
                                                            item-value="id" persistent-hint
                                                            :rules="[v => !!v || 'required']"></v-select>
                                                    </v-col>

                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="close1">Cancel</v-btn>
                                            <v-btn color="primary darken-1" @click="edit">Update</v-btn>
                                            <!-- :disabled="!valid" -->
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>




                            <v-btn class="primary" @click="addrolelinks_dialog()">
                                <v-icon>mdi mdi-plus</v-icon>New Role Links
                            </v-btn>

                        </v-toolbar>
                    </template>
                    <template v-slot:item.isactive="{ item }">
                        <v-switch v-model="item.isactive" @click="activeswitch(item)"></v-switch>
                    </template>
                    <template v-slot:item.ishidefromfront="{ item }">
                        <v-switch v-model="item.ishidefromfront" @click="ishideswitch(item)"></v-switch>
                    </template>


                    <template v-slot:item.action="{ item }">
                        <div>
                            <v-avatar style="" size="25" class="edit-avatar">
                                <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
                            </v-avatar>
                            <v-btn text>
                                <v-icon color="red" @click="deletefun(item.id)">
                                    mdi-delete</v-icon></v-btn>
                        </div>
                    </template>


                </v-data-table>




            </template>
        </div>

    </div>
</template>
<script>
import axios from "axios";


export default {

    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        rolelink_list: [],
        user_type_list: [],
        link_super_master_list: [],
        role_list: [],
        snackbar_msg: "",
        snackbar: false,
        color: "",
        linkname: '',
        link: '',
        linkdescription: '',
        sort_order: '',
        icon: '',
        usertype: '',
        role: [],
        link: '',
        isactive: true,
        ishidefromfront: true,
        id: "",

        headers: [
            {
                text: "Sr. No",
                value: "sr_no"
            },

            {
                text: "Link Name",
                value: "linkname"
            },
            {
                text: "Link URL",
                value: "link"
            },
            {
                text: "Link Description",
                value: "linkdescription"
            },
            {
                text: "Icon",
                value: "icon"
            },
            {
                text: "Sort Order",
                value: "sort_order"
            },
            {
                text: "User Type",
                value: "usertype"
            },
            {
                text: "Role",
                value: "role"
            },
            {
                text: "Status",
                value: "isactive"
            },
            {
                text: "Is Hide",
                value: "ishidefromfront"
            },
            {
                text: "Actions",
                value: "action",
                sortable: false
            }

        ],
    }),




    mounted() {
        this.onload();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialog2(val) {
            val || this.close();
        },
        overlay(val) {
            val = () => {
                this.overlay = false;
            };
        },
    },
    methods: {
        editItem(item) {

            this.dialog2 = true;
            this.linkname = "";
            this.sort_order = "";
            this.icon = "";
            this.linkdescription = "";
            this.link = "";
            this.usertype = "";
            this.role = "";
            this.id = "";


            this.linkname = item.linkname;
            this.sort_order = item.sort_order;
            this.icon = item.icon;
            this.linkdescription = item.linkdescription;
            this.link = item.link;
            this.usertype = item.usertype_id;
            this.role = item.role_id;

            this.id = item.id;

        },
        close() {
            this.dialog = false;
        },
        addrolelinks_dialog() {
            this.linkname = "";
            this.sort_order = "";
            this.icon = "";
            this.linkdescription = "";
            this.link = "";
            this.usertype = "";
            this.role = [];
            this.isactive = true;
            this.ishidefromfront = true;
            this.id = "";
            this.dialog = true;
        },

        close1() {
            this.dialog2 = false;
        },
        onload() {
            axios
                .post("/AdminUser/fetchRoleLink_new")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.rolelink_list = res.data.rolelink_list
                        this.user_type_list = res.data.user_type_list
                        this.role_list = res.data.role_list
                        this.link_super_master_list = res.data.link_super_master_list
                        this.load = true;
                    }
                })
                .catch(error => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    // window.console.log(error);
                });
        },
        save() {
            if (this.linkname == "") {
                this.showSnackbar("red", "please Enter Link Name");
            } else if (this.sort_order == "") {
                this.showSnackbar("red", "please Enter Sort Order");
            } else if (this.icon == "") {
                this.showSnackbar("red", "please Enter Icon");
            } else if (this.linkdescription == "") {
                this.showSnackbar("red", "please Enter Link Description");
            } else if (this.link == "") {
                this.showSnackbar("red", "please Enter Link URL");
            } else if (this.usertype == "") {
                this.showSnackbar("red", "please Select User Type");
            } else if (this.role.length == 0) {
                this.showSnackbar("red", "please Select Role");
            } else {
                let formData = new FormData();
                formData.append("linkname", this.linkname);
                formData.append("sort_order", this.sort_order);
                formData.append("icon", this.icon);
                formData.append("linkdescription", this.linkdescription);
                formData.append("link", this.link);
                formData.append("role", this.role);
                formData.append("usertype", this.usertype);

                axios
                    .post("/AdminUser/saveRoleLink_new", formData)
                    .then(res => {
                        if (res.data.msg == "200") {
                            this.onload()

                            this.showSnackbar("#4caf50", "new Role link added  Successfully...");
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch(error => {
                        window.console.log(error);
                    });
            }
            this.close();
        },
        edit() {
            if (this.linkname == "") {
                this.showSnackbar("red", "please Enter Link Name");
            } else if (this.sort_order == "") {
                this.showSnackbar("red", "please Enter Sort Order");
            } else if (this.sort_order == "") {
                this.showSnackbar("red", "please Enter Sort Order");
            } else if (this.icon == "") {
                this.showSnackbar("red", "please Enter Icon");
            } else if (this.link == "") {
                this.showSnackbar("red", "please Enter Link URL");
            } else if (this.usertype == "") {
                this.showSnackbar("red", "please Select User Type");
            } else if (this.role.length == 0) {
                this.showSnackbar("red", "please Select Role");
            } else {
                let formData = new FormData();
                formData.append("linkname", this.linkname);
                formData.append("id", this.id);
                formData.append("sort_order", this.sort_order);
                formData.append("icon", this.icon);
                formData.append("linkdescription", this.linkdescription);
                formData.append("link", this.link);
                formData.append("role", this.role);
                formData.append("usertype", this.usertype);

                axios
                    .post("/AdminUser/editRoleLink_new", formData)
                    .then(res => {
                        if (res.data.msg == "200") {
                            this.onload()
                            this.dialog2 = false;
                            this.showSnackbar("#4caf50", "Role Links Updated Successfully...");
                        }
                    })
                    .catch(error => {
                        window.console.log(error);
                    });
            }
            this.close();
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        activeswitch(item) {
            const data = {
                activeitem: item.id,
            };
            axios
                .post("/AdminUser/activeRoleLinks", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4caf50", res.data.message);
                        this.onload();
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
            this.close();
        },
        ishideswitch(item) {

            const data = {
                hideitem: item.id,
            };
            axios
                .post("/AdminUser/hideRoleLinks", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4caf50", res.data.message);
                        this.onload();
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
            this.close();
        },
        deletefun(item) {
            const data = {
                Deleteitem: item,
            };
            if (confirm("Do you really want to delete?")) {
                axios
                    .post("/AdminUser/deleterolelinks", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            // console.log("success");
                            this.showSnackbar("#4caf50", "Deleted Successfully...");
                            this.onload();
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                            //error
                        }
                        this.onload();
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
                this.close();
            }
        },


    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}


.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}



.edit-v-icon:hover {
    color: white;
}

.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}

.mar {
    padding: 5px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}
</style>
